import React, { useState, useEffect} from 'react'
import {graphql} from 'gatsby'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
const BlockContent = require('@sanity/block-content-to-react')
import SEO from '../components/utility/SEO'

import CoachPopup from '../components/about/CoachPopup'

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )
  }
}

const StyledBanner = styled(BackgroundImage)`
  display:grid;
  grid-template-columns:100%;
  padding:7.5vh 5vw;
  color:var(--color-white);

  text-align:center;


  opacity: 1 !important;
  background-size: cover;
  background-color: rgba(0,0,0,0.65);

  h1, p {
    align-self:center;
  }
  h1 {
    letter-spacing:2px;
  }
  p {
    font-size:1.2rem;
    font-weight:400;
    letter-spacing:1.5px;
    font-style:italic;
  }

`
const FirstContentContainer = styled.div`
  display:grid;
  grid-template-columns: 100%;
  max-width:1000px;
  margin: auto;
  padding: 5vh 5vw;

  .table-container {
    display:grid;
    justify-content:center;
    align-content:start;
    text-align:center;
    margin-top: 2rem;
  }
  @media only screen and (min-width: 600px) {
    padding:5vh 10vw;
  }

  @media only screen and (min-width: 800px) {
    padding:5vh 5vw;
    grid-template-columns: 50% 50%;
    .table-container {
      margin:initial;
    }
  }
  /* @media only screen and (min-width: 800px) {
    grid-template-columns: 32% 32% 32%;
  } */


  &.single-column {
    grid-template-columns: 100%;
  }

  blockquote {
    text-align:left;
    line-height:1.5rem;
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    em, p {
      display:inline;
    }
  }
  blockquote:before {
    content: open-quote;
    font-size: 4em;
    line-height: .1em;
    margin-right: .25em;
    vertical-align: -.4em;
  }

  p {
    line-height:1.75rem;
    letter-spacing:0.9px;
  }
  ul {
    /* padding-left:1.25rem; */
    li {
      letter-spacing:1px;
      line-height:1.5rem;
      font-size:1.1rem;
      font-weight:300;
    }
  }


`

const TableContainer = styled.div`
  table {
    border-spacing:0;
    margin-top: 1rem;
    th {
      padding:0.25rem;
      text-align:left;
    }
    td:nth-child(even) {
      background-color:var(--color-soft-white);
    }
    td {
      text-align:center;
      padding: 0.5rem 0;
      border-left: 0.5px solid var(--color-black);
      border-right: 0.5px solid var(--color-black);
    }
    td:first-child {
      border-left:none;
    }
    td:last-child {
      border-right:none;
    }
  }
  div {
    overflow-x:scroll;
  }

  &>:nth-child(2) {
    margin-left:1rem;
  }
`
const MembershipsWrapper = styled.div`
  display:grid;
  justify-items:center;
  padding:5vh 5vw;
  grid-template-columns:100%;
  background-color: var(--color-white-secondary);

  h2 {
    grid-row:1;
  }
`
const MembershipsContainer = styled.div`
  display:grid;
  grid-auto-flow:row;
  grid-template-columns: 50% 50%;
  justify-content:space-around;

  @media only screen and (max-width: 400px) {
    grid-template-columns: 100%;
  }
  @media only screen and (min-width: 600px) {
    grid-template-columns: 47.5% 47.5%;
  }
  @media only screen and (min-width: 800px) {
    grid-template-columns: 32% 32% 32%;
  }
  @media only screen and (min-width: 1000px) {
    grid-template-columns: repeat(4, minmax(200px, 250px));
    justify-content: space-between;
  }
  margin-top:1rem;
`
const Membership = styled.div`
  display:grid;
  align-content:space-between;
  position:relative;
  padding:0.5rem;
  margin:0.5rem 0.25rem;
  border:1px solid var(--color-black);
  border-radius:8px;
  background-color:var(--color-white);
  overflow:hidden;
  span {
    content: "Popular";
    position:absolute;
    background-color:var(--color-accent-yellow);
    right: -2rem;
    top: 1rem;
    transform: rotate(45deg);
    padding: 8px 36px;
  }
  button {
    width: calc(100% - 2rem);
    margin: 0.5rem 1rem;
  }
  button:hover {
    background-color:var(--color-accent-blue);
  }
`

const CoachesWrapper = styled.div`
  display:grid;
  grid-template-columns:100%;
  padding:5vh 5vw;
  h2 {
    text-align:center;
    margin-bottom:1rem;
  }
  @media only screen and (min-width:1000px) {
    margin: auto;
  }
`
const CoachesGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: 47.5% 47.5%;
  grid-row-gap:1rem;
  grid-column-gap:5%;
  justify-self:stretch;
  @media only screen and (min-width: 800px) {
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 5%;
  }
  @media only screen and (min-width: 1000px) {
    grid-template-columns: repeat(4, minmax(200px, 250px));
    grid-column-gap: 1%;
    justify-self:center;
  }
`
const CoachContainer = styled(motion.div)`
  transition: var(--transition);
  &:hover {
    transform: scale(1.05);
    transition: var(--transition);
  }
  & > h4 {
    text-align:center;
    margin: 0.5rem 0;
  }
  & > div > div.gatsby-image-wrapper {
    cursor:pointer;
    border-radius: 4px;
  }
`

const ProgramTemplate = ({data}) => {
  const { sanitySettings } = data
  const { title, description, _rawSchedule, mainImage, _rawBody, pricing, coaches } = data.post
  const coachArray = coaches.map((coach) => coach._id)
  const [[activeCoach, _id], setActiveCoach] = useState([undefined, undefined])

   useEffect(() => {
    //Disable <body> scrolling when modal popup is active
    if(activeCoach) {
      document.getElementsByTagName('body')[0].style.overflow="hidden";
    }
    if(activeCoach === undefined) {
      document.getElementsByTagName('body')[0].style.overflow="initial";
    }
  })
  const container = {
    hidden: { opacity: 0},
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.075,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        staggerChildren: 0,
        duration: .1
      }
    }
  };
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };


  return (
    <>
      <SEO title={`Programs | ${title}`} description={description} keywords={sanitySettings.keywords} />
      <StyledBanner
        fluid={mainImage.asset.fluid}
      >
        <h1>{title}</h1>
        <p>{description}</p>
      </StyledBanner>
      <FirstContentContainer className={_rawSchedule.rows[0].cells[0] == '' && "single-column"}>
        <BlockContent blocks={_rawBody} serializers={serializers}/>
        {_rawSchedule.rows[0].cells[0] !== '' && (
          <TableContainer className="table-container">
            <h2>Schedule</h2>
            <div>
              <table>
                {_rawSchedule.rows.map((row, index) => {
                  if(index===0) { return (
                    <thead key={index}>
                      <tr key={row._key}>
                        {row.cells.map((cell, index) => (
                          <th key={`cell-${index}`}>{cell}</th>
                        ))}
                      </tr>
                    </thead>
                  )}
                })}
                <tbody>
                  {_rawSchedule.rows.map((row, index) => {
                    if(index!==0) { return (
                      <tr key={row._key}>
                        {row.cells.map((cell, index) => (
                          <td key={`cell-${index}`}>{cell}</td>
                        ))}
                      </tr>
                    )}
                  })}
                </tbody>
              </table>
            </div>
          </TableContainer>
        )}
      </FirstContentContainer>
      {pricing.length !== 0 && (
        <MembershipsWrapper>
          <h2>Memberships</h2>
          <MembershipsContainer className={pricing.length > 4 && "double-row"}>
            {pricing.map((price, index) => (
              <Membership key={`membership-${index}`}>
                {price.highlighted && (<span>Popular</span>)}
                <h4>{price.membershipTitle}</h4>
                <p>{price.description}</p>
                <a href={price.purchaseURL}>
                  <button >Sign Up</button>
                </a>
              </Membership>
            ))}
          </MembershipsContainer>
        </MembershipsWrapper>
      )}
      <CoachesWrapper>
        <h2>{title} Staff</h2>
          <CoachesGrid variants={container} initial="hidden" animate="show">
            {coaches.map((coach, index) => (
              <motion.div whileTap={{scale: .95, transition: {duration: 0.15}}} key={index} variants={item}>
                <CoachContainer>
                  <div onClick={() => setActiveCoach([coach, coach._id])}>
                    <Img fluid={coach.image.asset.fluid} />
                  </div>
                  <h4>{coach.name}</h4>
                </CoachContainer>
              </motion.div>
            ))}
            <CoachPopup idArray={coachArray} coach={activeCoach} coachId={_id} handleCoachChange={setActiveCoach} />
          </CoachesGrid>
      </CoachesWrapper>
    </>
  )
}

export const query = graphql`
  query ProgramTemplateQuery($id: String!) {
    sanitySettings {
      keywords
    }
    post: sanityProgram(_id: {eq: $id}) {
      title
      description
      mainImage {
        asset {
          fluid(maxWidth:1920) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
      _rawSchedule
      _rawBody
      pricing {
        membershipTitle
        interval
        price
        purchaseURL
        description
        highlighted
      }
      coaches {
        _id
        name
        image {
          asset {
            fluid(maxWidth: 600, maxHeight: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        secondImage {
          asset {
            fluid(maxHeight:600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        program {
          title
          slug {
            current
          }
        }
        certifications
        _rawBio
      }
    }
  }
`

export default ProgramTemplate
